@import "fonts";
@import "colors";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  font-size: 12px;
  font-weight: 400;
  font-family: Montserrat, Arial, sans-serif;
  line-height: 1.2;
  color: #000;
  background-color: #fff;
  background-image: url('../img/bg.jpg');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
ol, ul {
  list-style-position: inside;
}

.wrap {
  width: 100%;
  max-width: 910px;
  margin: 0 auto;
  padding: 0 10px;
}

header {
  background-color: @green;

  &.wrap {
    padding: 10px;
    margin-bottom: 15px;
  }
  .header {
    &__burger {
      display: none;
      position: relative;
      width: 25px;
      height: 16px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #fff;
        transition: all .3s ease;

        &:nth-child(2) {
          top: 7px;
        }
        &:nth-child(3) {
          top: 14px;
        }
      }
    }
    &__nav {
      color: #fff;
      font-size: 15px;
      font-weight: 700;

      ul {
        list-style: none;
        display: flex;
        justify-content: space-around;
        align-items: center;

        li {
          position: relative;
          text-align: center;

          &:before {
            display: block;
            content: '';
            position: absolute;
            bottom: 4px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #fff;
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease;
          }
          &.current,
          &:hover {
            &:before {
              opacity: 1;
              visibility: visible;
              bottom: 0;
            }
          }
        }
      }
    }
  }
}

main {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  background-color: #fff;
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.25);

  &.wrap {
    margin-bottom: 15px;
  }
}

article {
  flex: 1;
}

aside {
  width: 240px;
  margin: 15px 0 15px 10px;
  padding: 15px 0 15px 10px;
  border-left: 1px solid @green;

  .sidebar-block {
    margin-bottom: 25px;

    &__title {
      display: block;
      background-color: @green;
      text-align: center;
      font-weight: 500;
      color: #fff;
      padding: 3px 5px;
      margin-bottom: 8px;
    }
    &__list {
      list-style: none;

      li {
        margin-bottom: 12px;

        &.current {
          font-weight: 500;
        } 
      }
      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.content {
  padding: 20px 0;
  line-height: 1.3;

  & > * {
    margin-bottom: 15px;
  }
  h1, h2, h3, h4, h5, h6 {
    text-align: center;
    font-weight: 500;
  }
  h1 {
    font-size: 20px;
    font-weight: 700;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }
  h4, h5, h6 {
    font-size: 14px;
  }
  ul, ol {
    padding-left: 20px;
  }
  blockquote {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 1px 1px 10px 1px rgba(22, 131, 104, 0.5);
    text-align: center;
    font-weight: 500;
    font-style: italic;
    padding: 10px 15px;
  }
  img {
    &.full {
      width: 100%;
    }
    &.left {
      float: left;
      margin-right: 15px;
    }
    &.right {
      float: right;
      margin-left: 15px;
    }
  }
  table {
    max-width: 100%;
    margin: 0 auto 15px;
    border-collapse: collapse;
    background-color: rgba(48, 141, 95, 0.1);

    td {
      font-weight: 700;
      text-align: center;
      border: 0.1px solid #b2b1b1;
      padding: 3px 20px;
      min-width: 65px;
    }
  }
}

footer {
  background-color: @green;
  
  .footer {
    &__copyright {
      font-size: 15px;
      font-weight: 500;
      text-align: center;
      color: #fff;
      padding: 10px 0;
    }
  }
}

@import "media";