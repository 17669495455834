@media screen and (max-width: 768px) {
  header {
    .header {
      &__burger {
        display: block;

        &.active {
          span {
            &:nth-child(1) {
              transform: rotate(33deg);
              transform-origin: left;
            }
            &:nth-child(2) {
              width: 0;
            }
            &:nth-child(3) {
              transform: rotate(-33deg);
              transform-origin: left;
            }
          }
        }
      }

      &__nav {
        position: absolute;
        top: -20px;
        left: 35px;
        background-color: @green;
        padding: 5px;
        max-width: 150px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;

        &.active {
          top: 10px;
          opacity: 1;
          visibility: visible;
        }
        ul {
          flex-direction: column;

          li {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  main {
    flex-direction: column;
  }

  aside {
    border: none;
    width: 100%;
    margin: 0;
    padding: 0;

    .sidebar-block {
      &__list {
        li {
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .content {
    table {
      td {
        min-width: auto;
      }
    }
    img {
      &.left {
        float: none;
        margin-right: 0;
        width: 100%;
      }
      &.right {
        float: none;
        margin-left: 0;
        width: 100%;
      }
    }
  }
}